export const Configurations = [
    {
        assets: "#679267",
        url: "https://readysitgodogtraining.com/",
        Id: "PCB00108672",
        icon: "ReadySitGo",
        title: "Profesional Dog Trainer in Denver Metro Area | Ready Sit Go Dog Training",
        description: "Top Profesional Dog Trainer Expert in South Denver. In home custom dog training. We offer reliable, off leash dog training in everyday scenarios."
    },
    {
        assets: "#679267",
        url: "https://www.readysitgodogtraining.com/",
        Id: "PCB00108672",
        icon: "ReadySitGo",
        title: "Profesional Dog Trainer in Denver Metro Area | Ready Sit Go Dog Training",
        description: "Top Profesional Dog Trainer Expert in South Denver. In home custom dog training. We offer reliable, off leash dog training in everyday scenarios."
    }
]
