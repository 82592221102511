import React, {Suspense} from 'react';
import blueColorLoader from './assets/images/Loaders/BlueColorLoader.gif';
import purpleColorLoader from './assets/images/Loaders/PurpleColorLoader.gif';
import greenColorLoader from './assets/images/Loaders/GreenColorLoader.gif';
import orangeColorLoader from './assets/images/Loaders/OrangeColorLoader.gif';
import pinkColorLoader from './assets/images/Loaders/PinkColorLoader.gif';
import redColorLoader from './assets/images/Loaders/RedColorLoader.gif';
import brownColorLoader from './assets/images/Loaders/BrownColorLoader.gif';
import charcoalColorLoader from './assets/images/Loaders/CharcoalColorLoader.gif';

import {setFaviconAndMetaData} from "./utils";
import {Configurations as reactUrls} from './Configurations.js';
import CacheBuster from "react-cache-buster";
import {version} from '../package.json';

const BlueTheme = React.lazy(() => import('./themes/BlueTheme'));
const PurpleTheme = React.lazy(() => import('./themes/PurpleTheme'));
const OrangeTheme = React.lazy(() => import('./themes/OrangeTheme'));
const PinkTheme = React.lazy(() => import('./themes/PinkTheme'));
const GreenTheme = React.lazy(() => import('./themes/GreenTheme'));
const RedTheme = React.lazy(() => import('./themes/RedTheme'));
const BrownTheme = React.lazy(() => import('./themes/BrownTheme'));
const CharcoalTheme = React.lazy(() => import('./themes/CharcoalTheme'));
const CustomTheme679267 = React.lazy(() => import('./themes/CustomTheme679267'));
const CustomTheme71BC78 = React.lazy(() => import('./themes/CustomTheme71BC78'));
const CustomTheme7CB9E8 = React.lazy(() => import('./themes/CustomTheme7CB9E8'));
const CustomTheme87CEEB = React.lazy(() => import('./themes/CustomTheme87CEEB'));

class ThemeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: localStorage.getItem('color') || 'Blue',
            staging: true
        };
    }

    componentWillMount() {
        let loc = window.location;
        if (loc.pathname !== "/") {
            window.location.href = window.location.origin;
        }
        let url = window.location.href;
        let elem = reactUrls && reactUrls.find(el => el.url === url);
        let data = {
            icon: elem && elem.icon,
            description: elem && elem.description,
            title: elem && elem.title
        }
        setFaviconAndMetaData(data);
        if (url && url !== 'http://masculinev1.petconnectbusiness.com/' && localStorage.getItem('color') !== undefined) {
            localStorage.setItem('color', elem.assets);
            this.setState({
                color: elem.assets,
                staging: false
            })
        } else if (localStorage.getItem('color') !== undefined) {
            localStorage.setItem('color', 'Blue');
        }
    }

    getColorAndPCB() {
        let url = window.location.href;
        let elem = reactUrls && reactUrls.find(el => el.url === url);
        if (url && url === 'http://masculinev1.petconnectbusiness.com/') {
            return {color: localStorage.getItem('color'), id: elem && elem.Id}
        } else {
            return {color: elem && elem.assets, id: elem && elem.Id};
        }
    }

    colorDropDown = () => {
        if (this.state.staging) {
            return (
                <div className='colorselector'>
                    <div className='content'>
                        <select value={this.state.color} className="service-options"
                                onChange={(e) => this.handleColorChange(e)}>
                            <option value='Blue'>Blue</option>
                            <option value='Brown'>Brown</option>
                            <option value='Charcoal'>Charcoal</option>
                            <option value='Green'>Green</option>
                            <option value='Orange'>Orange</option>
                            <option value='Pink'>Pink</option>
                            <option value='Purple'>Purple</option>
                            <option value='Red'>Red</option>
                        </select>
                    </div>
                </div>
            )
        }
    }

    render() {
        let colorAndPCB = this.getColorAndPCB();
        if (this.state.color === "Blue") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={blueColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={blueColorLoader} alt=''/></div>}>
                            <BlueTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Purple") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={purpleColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={purpleColorLoader} alt=''/></div>}>
                            <PurpleTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Pink") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={pinkColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={pinkColorLoader} alt=''/></div>}>
                            <PinkTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Orange") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={orangeColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={orangeColorLoader} alt=''/></div>}>
                            <OrangeTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Green") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={greenColorLoader} alt=''/></div>}>
                            <GreenTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Red") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={redColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={redColorLoader} alt=''/></div>}>
                            <RedTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (colorAndPCB.color === "Brown") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={brownColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={brownColorLoader} alt=''/></div>}>
                            <BrownTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === "Charcoal") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={charcoalColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense
                            fallback={<div className="progress_bar"><img src={charcoalColorLoader} alt=''/></div>}>
                            <CharcoalTheme PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === "#679267") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={greenColorLoader} alt=''/></div>}>
                            <CustomTheme679267 PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === "#71BC78") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={greenColorLoader} alt=''/></div>}>
                            <CustomTheme71BC78 PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === "#7CB9E8") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={greenColorLoader} alt=''/></div>}>
                            <CustomTheme7CB9E8 PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === "#87CEEB") {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <div>
                        <Suspense fallback={<div className="progress_bar"><img src={redColorLoader} alt=''/></div>}>
                            <CustomTheme87CEEB PCBID={colorAndPCB.id} color={colorAndPCB.color}/>
                        </Suspense>
                    </div>
                </CacheBuster>
            )
        } else if (this.state.color === '') {
            return (
                <CacheBuster
                    currentVersion={version}
                    isVerboseMode={true}
                    isEnabled={true}
                    loadingComponent={<img src={greenColorLoader} alt=''/>}
                >
                    <Suspense fallback={<div className="progress_bar"><img src={blueColorLoader} alt=''/></div>}>
                    </Suspense>
                </CacheBuster>
            )
        }
    }

    handleColorChange = (event) => {
        let value = event.target.value;
        localStorage.setItem('color', value);
        window.location.reload(true);
    }
}

export default ThemeSelector;
